<template>
  <div id="header-right">
    <v-row
      align="center"
      justify="end"
    >
      <v-spacer />

      <StUserMenu
        class="mr-4"
        :user="user"
        :greeting="greeting"
        :logout-label="$t('App.header.menus.logout')"
        :items="allowedItems"
        :user-image-path="avatar"
        @menu-list-item-clicked="onMenuClicked"
        @logout-item-clicked="logoutAndSendUserToLoginPage"
        @terms-clicked="openTerms"
        @privacy-policy-clicked="openPolicy"
      />
    </v-row>
  </div>
</template>

<script>
import StUserMenu from '@stilingue/st-user-menu/StUserMenu';
import { mapState } from 'vuex';
import userMenuItems from '@/mixins/user-menu-items.mixin';
import { LINK_TO_TERMS, LINK_TO_POLICY } from '@/utils/constants';

import { logoutAndSendUserToLoginPage } from '@/services/auth_manager';
export default {
  name: 'HeaderRight',
  components: {
    StUserMenu
  },
  mixins: [
    userMenuItems
  ],
  data() {
    return {
      avatar: 'avatarUrl'
    };
  },
  computed: {
    ...mapState('user', ['user', 'role']),
    greeting() {
      return this.$t('App.header.greeting');
    }
  },
  methods: {
    logoutAndSendUserToLoginPage,
    openPolicy() {
      window.open(LINK_TO_POLICY, '_blank');
    },
    openTerms() {
      window.open(LINK_TO_TERMS, '_blank');
    },
    onMenuClicked(menu) {
      if (menu.openInExternalPage) {
        return window.open(menu.href, '_blank');
      }

      // Vue Router throws error when we try to navigate to a route where we are already
      // This empty catch prevents the component to break
      return this.$router.push({ name: menu.href })
        .catch(/*istanbul ignore next*/() => {});
    }
  }
};
</script>

<style lang="scss" scoped>
  #header-right {
    width: 80px;
  }
</style>
