var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "header-right" } },
    [
      _c(
        "v-row",
        { attrs: { align: "center", justify: "end" } },
        [
          _c("v-spacer"),
          _c("StUserMenu", {
            staticClass: "mr-4",
            attrs: {
              user: _vm.user,
              greeting: _vm.greeting,
              "logout-label": _vm.$t("App.header.menus.logout"),
              items: _vm.allowedItems,
              "user-image-path": _vm.avatar
            },
            on: {
              "menu-list-item-clicked": _vm.onMenuClicked,
              "logout-item-clicked": _vm.logoutAndSendUserToLoginPage,
              "terms-clicked": _vm.openTerms,
              "privacy-policy-clicked": _vm.openPolicy
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }