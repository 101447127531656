import Vue from 'vue';
import router from '@/router';
import VueAnalytics from 'vue-analytics';
import { isNotRunningLocally } from '@/utils/environment_controller';

export function insertAnalyticsMetadata({
  user_email,
  user_name,
  user_id,
  user_group,
  user_group_id
} = {}) {
  Vue.use(VueAnalytics, {
    id: 'UA-62652307-9',
    debug: {
      enabled: !isNotRunningLocally(),
      sendHitTask: isNotRunningLocally()
    },
    router,
    autoTracking: {
      pageviewTemplate(route) {
        return {
          page: route.path,
          title: document.title,
          location: window.location.href
        };
      }
    }
  });

  Vue.$ga.set('userId', user_id);
  Vue.$ga.set('dimension1', String(user_name));
  Vue.$ga.set('dimension2', String(user_email));
  Vue.$ga.set('dimension3', String(user_group));
  Vue.$ga.set('dimension4', String(user_group_id));
}
