var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list-group",
    {
      staticClass: "sidebar-nested-item",
      attrs: {
        ripple: false,
        value: _vm.isOpen,
        "active-class": "sidebar-nested-item--active"
      },
      on: { click: _vm.openFirstChild },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function() {
            return [
              _c("StIcon", {
                staticClass: "sidebar-nested-item__icon",
                attrs: { "icon-name": _vm.icon }
              }),
              _c(
                "v-list-item-title",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isSidebarMini,
                      expression: "!isSidebarMini"
                    }
                  ],
                  staticClass: "sidebar-nested-item__title"
                },
                [_vm._v("\n      " + _vm._s(_vm.title) + "\n    ")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    _vm._l(_vm.subItems, function(subItem) {
      return _c(
        "v-list-item",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isSidebarMini,
              expression: "!isSidebarMini"
            }
          ],
          key: subItem.name,
          staticClass: "sidebar-nested-item__item",
          attrs: {
            to: { name: subItem.url.name },
            ripple: false,
            exact: "",
            flat: "",
            dense: ""
          }
        },
        [
          _c(
            "v-list-item-icon",
            { staticClass: "mr-4" },
            [
              _c("StIcon", { attrs: { "icon-name": subItem.icon, size: "12" } })
            ],
            1
          ),
          _c("v-list-item-title", {
            domProps: { textContent: _vm._s(subItem.name) }
          })
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }