<template>
  <v-navigation-drawer
    v-model="drawerController"
    fixed
    clipped
    temporary
    dark
    width="260"
    class="navbar-drawer--dark mini-navbar-drawer elevation-5"
  >
    <StSystemNavigationDrawerList
      :subtitle="$t('App.navigationDrawer.modulesLabel')"
      max-width="244px"
      :items-obj="itemsInDrawer"
      :item-active="itemActive"
    />
  </v-navigation-drawer>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { MUTATE } from '@/store/modules/mutation_types';
import StSystemNavigationDrawerList from '@/components/base/StSystemNavigationDrawerList';

export default {
  name: 'StSystemNavigationDrawer',
  components: {
    StSystemNavigationDrawerList
  },
  data() {
    return {
      itemActive: 'https://configuracoes.stilingue.com.br',
      itemsInDrawer: [
        {
          name: this.$t('App.navigationDrawer.modules.tickets'),
          link: 'https://tickets.stilingue.com.br'
        }, {
          name: this.$t('App.navigationDrawer.modules.permissions'),
          link: 'https://configuracoes.stilingue.com.br'
        }, {
          name: this.$t('App.navigationDrawer.modules.warroom'),
          link: 'https://warroom.stilingue.com.br'
        },
        {
          name: this.$t('App.navigationDrawer.modules.sac'),
          link: 'https://warroom.stilingue.com.br/sac'
        },
        {
          name: this.$t('App.navigationDrawer.modules.performance'),
          link: 'https://warroom.stilingue.com.br/metricas-proprietarias'
        }
      ]
    };
  },
  computed: {
    ...mapState('misc', [
      'drawer'
    ]),
    drawerController: {
      get() {
        return this.drawer;
      },
      set(value) {
        this.mutate({
          property: 'drawer',
          with: value
        });
      }
    }
  },
  methods: {
    ...mapMutations('misc', {
      mutate: MUTATE
    })
  }
};
</script>

<style lang="scss" scoped>
  .navbar-drawer {
    &--dark {
      background-color: var(--gray-100) !important;
      max-height: calc(100vh - 50px);
      top: 50px !important;

      span {
        color: #98999d;
      }
    }
  }
</style>
