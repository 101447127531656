import { debuggingId } from '@/utils/math';
import jwt from 'jsonwebtoken';

/**
 * Don't run on localhost
 */
export function startCustomerSupport(flag, payload) {
  flag ? startZendesk(payload) : startIntercom(payload);
}

function startZendesk(payload) {
  const s = document.createElement('script');
  s.type = 'text/javascript';
  s.id = 'ze-snippet';
  s.src = 'https://static.zdassets.com/ekr/snippet.js?key=aef27157-dd7e-4833-8506-1def2716dd0d';
  const x = document.getElementsByTagName('script')[0];
  if (x) {
    x.parentNode.insertBefore(s, x);
  }
  s.addEventListener('load', () => {
    insertZendeskMetadata(payload);
    window.zE('webWidget', 'show');
  });
}

function generateJwt(payload) {
  const chatKey = 'D68105CEE6FC3FC6875F2EDD850DE67EAA06EBC8C16318222284B170E19FA0F4';
  if (!payload) {
    console.warn('Empty payload, Zendesk auth failed');
    return '';
  }

  const token = jwt.sign(payload, chatKey, {
    algorithm: 'HS256'
  });
  if (!token) {
    console.warn('Erron loading jwt, Zendesk auth failed');
    return '';
  }
  return token;
}

function jwtFn({ name, email, userId } = {}) {
  const payload = {
    iat: Math.round(new Date() / 1000), // time now in seconds
    jti: debuggingId(),
    name,
    email,
    external_id: String(userId)
  };
  return generateJwt(payload);
}

function startIntercom(payload) {
  const APP_ID = 'cuuvmvgm';

  window.intercomSettings = {
    app_id: APP_ID
  };
  const w = window;
  const d = document;
  const i = function() {
    // eslint-disable-next-line prefer-rest-params
    i.c(arguments);
  };
  i.q = [];
  i.c = function(args) {
    i.q.push(args);
  };
  w.Intercom = i;
  const s = d.createElement('script');
  s.type = 'text/javascript';
  s.src = `https://widget.intercom.io/widget/${APP_ID}`;
  const x = d.getElementsByTagName('script')[0];
  if (x && 'parentNode' in x) {
    x.parentNode.insertBefore(s, x);
  }
  s.addEventListener('load', () => {
    insertIntercomMetadata(payload);
  });
}

export function shutdownIntercom() {
  window.Intercom && window.Intercom('shutdown');
}

export function insertIntercomMetadata({ email, user_hash, userId } = {}) {
  if (typeof window.Intercom === 'function' && email && user_hash && userId) {
    window.Intercom('reattach_activator');
    window.Intercom('update', {
      app_id: 'cuuvmvgm',
      email,
      user_hash,
      logrocketURL: `https://app.logrocket.com/asbycy/stilingue-tickets/sessions?u=${userId}`
    });
    return true;
  }
  return false;
}

export function insertZendeskMetadata({ name, email, userId, company } = {}) {
  if (typeof window.zE !== 'function' || !name || !email || !userId) {
    console.warn(`invalid zendesk metadata ${name}, ${email}, ${userId}`);
    return false;
  }
  const addTagsParameters = [`Grupo Usuário=${company}`, 'Produto=Tickets'];

  const preFillData = {
    name: {
      value: name,
      readOnly: true // optional
    },
    email: {
      value: email,
      readOnly: true // optional
    }
  };
  const w = window;
  w.zE('webWidget', 'setLocale', 'pt-br');
  w.zE('webWidget', 'prefill', preFillData);
  w.zE(function() {
    // the tags will be used by the Chat only
    w.$zopim(function() {
      w.$zopim.livechat.addTags(...addTagsParameters);
    });
  });

  w.zESettings = {
    webWidget: {
      authenticate: {
        chat: jwtFn({ name, email, userId })
      }
    }
  };
  return true;
}
/* eslint-disable complexity */
export function toggleChatTool(zendeskFlagIsOn, payload) {
  const startedIntercom = Boolean(window.Intercom);
  const startedZendesk = Boolean(window.zE);

  if (zendeskFlagIsOn && startedIntercom) { // turned flag on after started with intercom
    window.Intercom('shutdown');
    startCustomerSupport(zendeskFlagIsOn, payload);
  } else if (startedZendesk && !zendeskFlagIsOn) { // turned flag off after started with zendesk
    window.zE('webWidget', 'hide');
    startCustomerSupport(zendeskFlagIsOn, payload);
  }
}
