var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.companies.length
    ? _c(
        "div",
        [
          _vm.companies.length > 1
            ? _c("v-select", {
                staticClass: "company-selector",
                attrs: {
                  value: _vm.selected,
                  items: _vm.companies,
                  placeholder: _vm.$t("App.company.placeholder"),
                  "item-text": "name",
                  "item-value": "id",
                  dense: ""
                },
                model: {
                  value: _vm.selected,
                  callback: function($$v) {
                    _vm.selected = $$v
                  },
                  expression: "selected"
                }
              })
            : _c("h4", { staticClass: "white--text" }, [
                _vm._v("\n    " + _vm._s(_vm.companies[0].name) + "\n  ")
              ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }