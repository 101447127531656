<template>
  <v-list
    class="navbar-list"
    :style="[mini ? { 'width': '50px' } : { 'width': maxWidth }]"
  >
    <v-subheader
      v-if="subtitle"
      class="navbar-list__subheader"
    >
      {{ subtitle }}
    </v-subheader>

    <div
      v-for="item in itemsObj"
      :key="item.name"
      :href="item.link"
      class="navbar-list__item"
      :class="isActiveItem(item)"
      @click.stop="goTo(item)"
    >
      <v-list-item v-ripple>
        <v-list-item-action v-if="item.icon">
          <StIcon
            size="20"
            :icon-name="item.icon"
            v-bind="item.iconProps ? {color: item.iconProps.color, size: item.iconProps.size} : {}"
            class="navbar-list-icon"
          />
        </v-list-item-action>

        <v-list-item-content>
          <v-list-item-title class="font-weight-bold">
            {{ item.name }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </div>

    <v-divider
      v-if="returnToProduct"
      class="navbar-list__divider"
    />
    <div
      v-if="returnToProduct"
      class="navbar-list__item navbar-list__item__return"
    >
      <v-list-item>
        <v-list-item-action>
          <StIcon
            icon-name="arrow-left"
            size="20"
            class="navbar-list-icon"
          />
        </v-list-item-action>
        <v-list-item-content>
          <v-subheader class="navbar-list__item__return__subheader">
            {{ $t('App.navigationDrawer.back') }}
          </v-subheader>
          <v-list-item-title
            class="navbar-list__item__return__title"
            @click="navigate(previousProduct.link)"
          >
            {{ previousProduct.name }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </div>
  </v-list>
</template>

<script>
import StIcon from '@stilingue/st-icon/StIcon';
import { goToSystemPage } from '@/utils/navigation';

export default {
  name: 'StSystemNavigationDrawerList',
  components: {
    StIcon
  },
  props: {
    itemsObj: {
      type: Array,
      required: true
    },
    subtitle: {
      type: String,
      default: ''
    },
    itemActive: {
      type: String,
      default: ''
    },
    openInExternalPage: {
      type: Boolean,
      default: true
    },
    mini: {
      type: Boolean,
      default: false
    },
    maxWidth: {
      type: String,
      default: '200px',
      validator(v) {
        return v.includes('px');
      }
    },
    previousProduct: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    returnToProduct() {
      return !this.mini && this.previousProduct && Object.keys(this.previousProduct).length !== 0;
    }
  },
  methods: {
    isActiveItem(item) {
      return {
        'active': item.link === this.itemActive
      };
    },
    navigate(link) {
      /* istanbul ignore next  */
      goToSystemPage(link, window);
    },
    goTo(item) {
      this.$emit('change', item.link);

      if (this.openInExternalPage) {
        const win = window.open(item.link, '_blank');
        win.focus();
        return false;
      }

      this.$router.push(item.link);
    }
  }
};
</script>

<style lang="scss" scoped>
  .v-list {
    padding: 0;

    &__item {
      &__action {
        min-width: 30px;
      }
    }
  }

  .v-subheader {
    font-size: 12px !important;
    font-weight: 600;
    text-transform: uppercase;
    text-align: left;
    transition: opacity 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    cursor: default !important;
  }

  .v-navigation-drawer--mini-variant .v-subheader {
    display: flex !important;
    opacity: 0;
  }

  .v-navigation-drawer--mini-variant .v-list__item__action {
    min-width: unset;
  }

  .navbar-list {
    background: var(--gray-100);
    margin: 10px;

    * {
      font-size: 16px;
      border-radius: 6px;
      color: var(--gray-300);
      cursor: pointer;
      -webkit-transition: all 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
      -moz-transition: all 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
      -o-transition: all 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
      transition: all 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    }

    &__divider {
      margin-top: 15px;
    }

    &__subheader {
      line-height: 1.42;
      color: var(--default-green-color) !important;
      text-transform: uppercase;
    }

    &__item__return {
      margin-top: 15px;
    }

    &__item__return__subheader {
      padding: 0;
      height: 16px;
      cursor: pointer !important;
    }

    &__item__return__title {
      font-weight: 600;
      line-height: 1.13;
      height: 22px;
    }

    .item--image.active {
      fill: var(--gray-050);
    }

    &__item.active {
      * {
        background: var(--gray-050);
      }

      .v-list__item__title {
        cursor: default !important;
        color: var(--white) !important;
      }

      .v-icon {
        color: var(--default-green-color) !important;
      }
    }

    &__item:hover {
      * {
        background: var(--gray-050);
        color: var(--white);
      }
    }

    .navbar-list-icon {
      margin-right: 10px;
      margin-bottom: 5px;
    }
  }
</style>
