
export default {
  computed: {
    items() {
      return [
        {
          id: 1,
          name: this.$t('App.header.menus.home'),
          icon: 'home',
          href: 'notification-settings',
          openInExternalPage: false
        },
        {
          id: 2,
          name: this.$t('App.header.menus.account'),
          icon: 'account-multiple',
          href: 'https://warroom.stilingue.com.br/permissoes',
          openInExternalPage: true,
          roleCondition(conditions) {
            if (conditions.masterLock && conditions.isSuperAdminAccountAdminOrMaster && conditions.isMasterOrCanViewPermissions) {
              return true;
            }
            return !conditions.masterLock && conditions.isMasterOrCanViewPermissions;
          }
        },
        {
          id: 3,
          name: this.$t('App.header.menus.login'),
          icon: 'account',
          href: 'https://warroom.stilingue.com.br/perfil',
          openInExternalPage: true
        }
      ];
    },
    allowedItems() {
      const conditions = this.role && {
        masterLock: this.role.masterLock,
        isMasterOrCanViewPermissions: this.checkIsMasterOrCanViewPermissions(this.role, this.user),
        isSuperAdminAccountAdminOrMaster: this.checkIsSuperAdminAccountAdminOrMaster(this.role),
        isAdminOrAccountAdmin: this.checkIsAdminOrAccountAdmin(this.role, this.user)
      };
      return this.items.filter((item) => {
        if (item.roleCondition === undefined) {
          return true;
        }
        if (Object.keys(this.role).length === 0) {
          return false;
        }
        return item.roleCondition(conditions);
      });
    }
  },
  methods: {
    checkIsSuperAdminAccountAdminOrMaster(role) {
      return role.isSuperAdmin || role.isAccountAdmin || role.master;
    },
    checkIsAdminOrAccountAdmin(role, user) {
      return user.isAdmin || role.isAccountAdmin;
    },
    checkIsMasterOrCanViewPermissions(role, user) {
      return (user && user.master) || (role.permissions && role.permissions.canViewPermissions);
    }
  }
};
