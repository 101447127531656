import { insertSentryMetadata } from '@/plugins/sentry';
import { insertLogRocketMetadata } from '@/plugins/logrocket';
import { insertRolloutMetadata } from '@/plugins/rollout';
import { insertAnalyticsMetadata } from '@/plugins/analytics';
import LogRocket from 'logrocket';
import * as Sentry from '@sentry/browser';

export function insertPluginMetadata({
  user,
  activeCompany
}) {
  if (!user || !activeCompany) {
    console.warn('User OR Company not found, we could not set metadata plugins');
    return false;
  }

  /**
   * Sentry
   */
  insertSentryMetadata({
    username: user.name,
    email: user.email,
    id: user.id,
    user_group: activeCompany.name
  });

  /**
   * LogRocket
   */
  insertLogRocketMetadata({
    email: user.email,
    name: user.name,
    id: user.id,
    user_group: activeCompany.name,
    user_group_id: activeCompany.id
  });

  LogRocket.getSessionURL(sessionURL => {
    Sentry.configureScope(scope => {
      scope.setExtra('sessionURL', sessionURL);
    });
  });

  /**
   * Rollout
   */
  insertRolloutMetadata({
    user_email: user.email,
    user_name: user.name,
    user_id: user.id,
    user_group: activeCompany.name,
    user_group_id: activeCompany.id
  });

  /**
   * Analytic
   */
  insertAnalyticsMetadata({
    user_email: user.email,
    user_name: user.name,
    user_id: user.id,
    user_group: activeCompany.name,
    user_group_id: activeCompany.id
  });
}
