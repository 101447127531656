<template>
  <div id="page-layout">
    <StHeader
      class="pl-8"
      :link="showHelpCenter ? articleLink : ''"
      @side-icon-clicked="onSideIconClicked"
    >
      <template #center>
        <CompanySelector
          class="mx-6"
          :companies="companies"
          :company-id="currentCompanyId"
          @updateCompany="updateCompany"
        />
      </template>

      <template #right>
        <HeaderRight />
      </template>
    </StHeader>

    <StSidebar
      :minimize="isSidebarMini"
      :permanent="true"
      @sidebar-changed="onSidebarChanged"
    >
      <template #items>
        <SidebarItemList :is-sidebar-mini="isSidebarMini" />
      </template>
    </StSidebar>

    <v-content
      class="content-white"
      :class="contentWrapperClass"
    >
      <v-row
        align="center"
        justify="center"
        class="fill-height"
      >
        <v-col
          class="st-permissions__container"
          md="12"
          lg="10"
        >
          <transition>
            <router-view />
          </transition>

          <StArticlesIndicator
            v-if="!showHelpCenter"
            :link="articleLink"
            :x="14"
            :y="76"
          />
        </v-col>
      </v-row>
    </v-content>

    <StSystemNavigationDrawer
      :drawer="isDrawerExpanded"
    />
  </div>
</template>

<script>
import StHeader from '@stilingue/st-header/StHeader';
import StSidebar from '@stilingue/st-sidebar/StSidebar';
import SidebarItemList from '@/components/base/SidebarItemList';
import CompanySelector from '@/components/base/CompanySelector';
import HeaderRight from '@/components/base/HeaderRight';
import StSystemNavigationDrawer from '@/components/base/StSystemNavigationDrawer';
import StArticlesIndicator from '@stilingue/st-articles-indicator';
import { insertPluginMetadata } from '@/plugins/insert_plugins_metadata';
import { startCustomerSupport, toggleChatTool } from '@/plugins/zendesk';

import { mapActions, mapMutations, mapState, mapGetters } from 'vuex';
import { PERMISSIONS_ARTICLE_URL, PERMISSIONS_ZENDESK_ARTICLE_URL } from '@/utils/constants';

export default {
  name: 'PageLayout',
  components: {
    StHeader,
    StSidebar,
    SidebarItemList,
    CompanySelector,
    HeaderRight,
    StSystemNavigationDrawer,
    StArticlesIndicator,
  },
  props: {
    companyId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isSidebarMini: false,
      isDrawerExpanded: false
    };
  },
  computed: {
    contentWrapperClass() {
      return {
        'content-wrapper__expanded': !this.isSidebarMini,
        'content-wrapper': this.isSidebarMini
      };
    },
    ...mapState('company', [
      'companies',
      'activeCompany'
    ]),
    ...mapState('user', [
      'user'
    ]),
    ...mapGetters('stFeatureFlag', [
      'isEnabled',
    ]),
    showHelpCenter() {
      return this.isEnabled('show-zendesk-guide');
    },
    useZendesk() {
      return this.isEnabled('use-zendesk');
    },
    currentCompanyId() {
      return this.activeCompany ? String(this.activeCompany.id) : this.companyId;
    },
    articleLink() {
      return this.isEnabled('show-zendesk-guide') ? PERMISSIONS_ZENDESK_ARTICLE_URL : PERMISSIONS_ARTICLE_URL;
    }
  },
  created() {
    insertPluginMetadata({
      user: this.user,
      activeCompany: this.activeCompany
    });
  },
  mounted() {
    if (!this.user) return;

    startCustomerSupport(this.useZendesk, {
      email: this.user.email,
      name: this.user.name,
      company: this.activeCompany.name,
      userId: this.user.id,
      user_hash: this.user.intercomHash,
    });
  },
  updated() {
    if (!this.user) return;

    toggleChatTool(this.useZendesk, {
      email: this.user.email,
      name: this.user.name,
      company: this.activeCompany.name,
      userId: this.user.id,
      user_hash: this.user.intercomHash,
    });
  },
  methods: {
    ...mapMutations('misc', {
      toggleDrawer: 'TOGGLE_DRAWER'
    }),
    ...mapActions('company', [
      'setActiveCompany'
    ]),
    onSidebarChanged(value) {
      /* istanbul ignore next  */
      this.isSidebarMini = value.mini;
    },
    onSideIconClicked() {
      /* istanbul ignore next  */
      this.toggleDrawer();
    },
    updateCompany(companyId) {
      this.setActiveCompany(companyId);

      this.$router.replace({
        name: 'home',
        params: {
          companyId: String(companyId),
          np_red: this.$route.name,
        },
      }).catch(/* istanbul ignore next */() => {});
    }
  }
};
</script>

<style lang="scss">
  * {
    font-family: 'Open Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    margin: 0;
    padding: 0;
  }

  .content-wrapper {
    margin-left: 70px;
  }

  .content-wrapper__expanded {
    margin-left: 223px;
  }

  .st-permissions__container {
    width: 100%;
    -webkit-transition: padding 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    -moz-transition: padding 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    -o-transition: padding 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    transition: padding 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    padding: 55px 30px;
  }

</style>
