import LogRocket from 'logrocket';
import { isNotRunningLocally } from '@/utils/environment_controller';

const shouldStartLockRocket = isNotRunningLocally();

if (shouldStartLockRocket) {
  LogRocket.init('asbycy/stilingue-permissions');
}

export function insertLogRocketMetadata({
  name,
  email,
  id,
  user_group,
  user_group_id
} = {}) {
  if ([shouldStartLockRocket, name, email, id, user_group, user_group_id].every(Boolean)) {
    LogRocket.identify(id, {
      name,
      email,
      user_group,
      user_group_id
    });
  }
}

