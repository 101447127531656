export const PERMISSIONS_ARTICLE_URL = 'https://conteudo.stilingue.com.br/pt-BR/articles/3437240-tickets';
export const PERMISSIONS_ZENDESK_ARTICLE_URL = 'https://suporte.stilingue.com.br/hc/pt-br';

export const LINK_MANAGE_ACCOUNT = window.LINK_MANAGE_ACCOUNT || '/conta';
export const LINK_TO_PERMISSIONS = window.LINK_TO_PERMISSIONS || '/permissoes';
export const LINK_TO_LOGIN = window.LINK_TO_LOGIN || '/perfil';
export const ENTER_KEY_CODE = 13;

export const LINK_TO_TERMS = 'https://stilingue.com.br/termos';
export const LINK_TO_POLICY = 'https://stilingue.com.br/politica';
