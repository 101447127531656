function generateSerializedField([key, value]) {
  return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
}

function serializeUrlEncoded(data) {
  const serializedFields = Object.entries(data).map(generateSerializedField, '?');
  return `?${serializedFields.join('&')}`;
}

/* istanbul ignore next  */
export function goToExternalPage(link, /* istanbul ignore next  */ params = {}, /* istanbul ignore next  */ w = window) {
  const url = link + serializeUrlEncoded(params);
  w.location.replace(url);
}

export function goToSystemPage(link, /* istanbul ignore next  */ w = window) {
  w.location.hash = '';
  w.location.pathname = link;
}
