var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list-item",
    {
      staticClass: "sidebar-item",
      attrs: { to: _vm.to, ripple: false },
      nativeOn: {
        click: function($event) {
          return _vm.$emit("click")
        }
      }
    },
    [
      _c(
        "v-list-item-icon",
        { class: _vm.iconMiniClass },
        [
          _c("StIcon", {
            staticClass: "sidebar-item__icon",
            attrs: { "icon-name": _vm.icon }
          })
        ],
        1
      ),
      _c("v-list-item-title", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isSidebarMini,
            expression: "!isSidebarMini"
          }
        ],
        staticClass: "sidebar-item__title",
        domProps: { textContent: _vm._s(_vm.name) }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }