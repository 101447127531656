
export default {
  computed: {
    items() {
      return [
        {
          icon: 'bell',
          name: this.$t('App.sidebar.notifications'),
          url: {
            name: 'notification-settings',
          },
        },
        {
          icon: 'flag',
          name: this.$t('App.sidebar.permissions'),
          url: {
            name: 'permissions',
          },
          children: [
            {
              icon: 'hexagon-multiple',
              name: this.$t('App.sidebar.roles'),
              url: {
                name: 'role-list',
              },
            },
            {
              icon: 'account-multiple',
              name: this.$t('App.sidebar.users'),
              url: {
                name: 'user-list',
              },
            },
          ],
        },
      ];
    }
  }
};
