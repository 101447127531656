<template>
  <div v-if="companies.length">
    <v-select
      v-if="companies.length > 1"
      v-model="selected"
      :value="selected"
      :items="companies"
      :placeholder="$t('App.company.placeholder')"
      item-text="name"
      item-value="id"
      class="company-selector"
      dense
    />
    <h4
      v-else
      class="white--text"
    >
      {{ companies[0].name }}
    </h4>
  </div>
</template>
<script>
export default {
  name: 'CompanySelector',
  props: {
    companies: {
      type: Array,
      default: () => ([])
    },
    companyId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      selected: null
    };
  },
  created() {
    this.selected = Number(this.companyId);
    this.$watch('selected', (company) => {
      this.$emit('updateCompany', company);
    });
  }
};
</script>
<style lang="scss" scoped>
  .company-selector {
    max-width: 200px;
  }
</style>
