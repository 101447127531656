var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list",
    {
      staticClass: "navbar-list",
      style: [_vm.mini ? { width: "50px" } : { width: _vm.maxWidth }]
    },
    [
      _vm.subtitle
        ? _c("v-subheader", { staticClass: "navbar-list__subheader" }, [
            _vm._v("\n    " + _vm._s(_vm.subtitle) + "\n  ")
          ])
        : _vm._e(),
      _vm._l(_vm.itemsObj, function(item) {
        return _c(
          "div",
          {
            key: item.name,
            staticClass: "navbar-list__item",
            class: _vm.isActiveItem(item),
            attrs: { href: item.link },
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.goTo(item)
              }
            }
          },
          [
            _c(
              "v-list-item",
              { directives: [{ name: "ripple", rawName: "v-ripple" }] },
              [
                item.icon
                  ? _c(
                      "v-list-item-action",
                      [
                        _c(
                          "StIcon",
                          _vm._b(
                            {
                              staticClass: "navbar-list-icon",
                              attrs: { size: "20", "icon-name": item.icon }
                            },
                            "StIcon",
                            item.iconProps
                              ? {
                                  color: item.iconProps.color,
                                  size: item.iconProps.size
                                }
                              : {},
                            false
                          )
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "v-list-item-content",
                  [
                    _c(
                      "v-list-item-title",
                      { staticClass: "font-weight-bold" },
                      [
                        _vm._v(
                          "\n          " + _vm._s(item.name) + "\n        "
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      }),
      _vm.returnToProduct
        ? _c("v-divider", { staticClass: "navbar-list__divider" })
        : _vm._e(),
      _vm.returnToProduct
        ? _c(
            "div",
            { staticClass: "navbar-list__item navbar-list__item__return" },
            [
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-action",
                    [
                      _c("StIcon", {
                        staticClass: "navbar-list-icon",
                        attrs: { "icon-name": "arrow-left", size: "20" }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    [
                      _c(
                        "v-subheader",
                        { staticClass: "navbar-list__item__return__subheader" },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("App.navigationDrawer.back")) +
                              "\n        "
                          )
                        ]
                      ),
                      _c(
                        "v-list-item-title",
                        {
                          staticClass: "navbar-list__item__return__title",
                          on: {
                            click: function($event) {
                              return _vm.navigate(_vm.previousProduct.link)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.previousProduct.name) +
                              "\n        "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }