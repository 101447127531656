var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-navigation-drawer",
    {
      staticClass: "navbar-drawer--dark mini-navbar-drawer elevation-5",
      attrs: { fixed: "", clipped: "", temporary: "", dark: "", width: "260" },
      model: {
        value: _vm.drawerController,
        callback: function($$v) {
          _vm.drawerController = $$v
        },
        expression: "drawerController"
      }
    },
    [
      _c("StSystemNavigationDrawerList", {
        attrs: {
          subtitle: _vm.$t("App.navigationDrawer.modulesLabel"),
          "max-width": "244px",
          "items-obj": _vm.itemsInDrawer,
          "item-active": _vm.itemActive
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }