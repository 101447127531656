var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "page-layout" } },
    [
      _c("StHeader", {
        staticClass: "pl-8",
        attrs: { link: _vm.showHelpCenter ? _vm.articleLink : "" },
        on: { "side-icon-clicked": _vm.onSideIconClicked },
        scopedSlots: _vm._u([
          {
            key: "center",
            fn: function() {
              return [
                _c("CompanySelector", {
                  staticClass: "mx-6",
                  attrs: {
                    companies: _vm.companies,
                    "company-id": _vm.currentCompanyId
                  },
                  on: { updateCompany: _vm.updateCompany }
                })
              ]
            },
            proxy: true
          },
          {
            key: "right",
            fn: function() {
              return [_c("HeaderRight")]
            },
            proxy: true
          }
        ])
      }),
      _c("StSidebar", {
        attrs: { minimize: _vm.isSidebarMini, permanent: true },
        on: { "sidebar-changed": _vm.onSidebarChanged },
        scopedSlots: _vm._u([
          {
            key: "items",
            fn: function() {
              return [
                _c("SidebarItemList", {
                  attrs: { "is-sidebar-mini": _vm.isSidebarMini }
                })
              ]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "v-content",
        { staticClass: "content-white", class: _vm.contentWrapperClass },
        [
          _c(
            "v-row",
            {
              staticClass: "fill-height",
              attrs: { align: "center", justify: "center" }
            },
            [
              _c(
                "v-col",
                {
                  staticClass: "st-permissions__container",
                  attrs: { md: "12", lg: "10" }
                },
                [
                  _c("transition", [_c("router-view")], 1),
                  !_vm.showHelpCenter
                    ? _c("StArticlesIndicator", {
                        attrs: { link: _vm.articleLink, x: 14, y: 76 }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("StSystemNavigationDrawer", {
        attrs: { drawer: _vm.isDrawerExpanded }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }