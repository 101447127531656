import { render, staticRenderFns } from "./SidebarItemList.vue?vue&type=template&id=486972ff&scoped=true&"
import script from "./SidebarItemList.vue?vue&type=script&lang=js&"
export * from "./SidebarItemList.vue?vue&type=script&lang=js&"
import style0 from "./SidebarItemList.vue?vue&type=style&index=0&id=486972ff&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "486972ff",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VList } from 'vuetify/lib/components/VList';
installComponents(component, {VList})


/* hot reload */
if (module.hot) {
  var api = require("/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('486972ff')) {
      api.createRecord('486972ff', component.options)
    } else {
      api.reload('486972ff', component.options)
    }
    module.hot.accept("./SidebarItemList.vue?vue&type=template&id=486972ff&scoped=true&", function () {
      api.rerender('486972ff', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/base/SidebarItemList.vue"
export default component.exports